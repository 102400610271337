import affiliatesFragment from './Storefront/affiliates';
import facetsLimitedFragment from './Storefront/facets/limited';
import {
	seasonalPartsFragment,
	seasonalPricingPartsFragment,
} from './Storefront/faq/seasonal';

/**
 * NOTE:
 * IF STOREFRONT FRAGMENT CHANGES -> UPDATE CACHE WARMER
 *
 */

const storefront = `
  fragment ProfileProperties on Storefront {
    id
    vendorId
    accountId
    locationId
    accountStatus
    name
    displayId
    adTier
    claimedStatus
    description
    displayWebsiteUrl
    headline
    isTestData
    marketCode
    movedProfileId
    purchaseStatus
    vendorTier
    isNew
    websiteUrl
    addOns {
      code
    }
    affiliates {
      id
      name
      description
      singular {
        slug
        term
      }
      plural {
        slug
        term
      }
      isSelected
      ...AffiliatesRecurse
    }
    ai {
      photo {
        height
        id
        mediaType
        url
        width
      }
    }
    bio {
      name
      description
      displayRole
      photo {
        id
        url
      }
    }
    orgDetails {
      serviceAreas
      travelAvailability
      startYear
      totalMembers
      videographyServices
      photographyServices
      languages
    }
    teamMembers {
        id
        name
        description
        role
        displayRole
        photo {
          sourceId
          name
          width
          height
          id
          url
          mediaType
          credit
        }
    }
    categories {
      id
      code
      name
      displayName
    }
    categoryInfo {
      plural {
        slug
        term
      }
    }
    designers {
      odbId
      name
      theme
      url
    }
    emails {
      address
      type
    }
    facets {
      id
      name
      ...FacetsRecurseLimited
    }
    faq {
      pricing {
        sheet {
          url
        }
        seasonal {
          ...SeasonalParts
        }
      }
    }
    location {
      address {
        address1
        address2
        city
        state
        postalCode
        latitude
        longitude
        isPublic
      }
      serviceArea
    }
    logo {
      url
    }
    mediaSummary {
      total
      media {
        ... on MediaPhoto {
          id
          width
          height
          url
          mediaType
        }
        ... on MediaVideo {
          id
          thumbnailUrl
          url
          mediaType
        }
        ... on MediaTour {
          id
          thumbnailUrl
          url
          mediaType
          embedFormat
        }
      }
    }
    phones {
      number
      type
      extension
    }
    pledges {
      plantation
    }
    reviewSummary {
      count
      overallRating
    }
    salesProfiles {
      vendorTier
      marketCode
      addOns {
        code
        name
      }
    }
    settings {
      AIPhoto
      pricing
      enhancedRFQ
    }
    siteUrls {
      siteId
      siteName
      uri
      relativeUri
    }
    socialMedia {
      code
      value
    }
    statistics {
      completionScore
    }
    storefrontCard {
      height
      id
      mediaType
      url
      width
    }
    vendorBehavior {
      quickResponder
    }
    pricing {
      offPeak {
        average {
          estimate {
            value
          }
        }
      }
    }
}

${affiliatesFragment}
${facetsLimitedFragment}
${seasonalPartsFragment}
${seasonalPricingPartsFragment}
`;

export default storefront;
