import { graphqlFetch } from '@api/graphql/gql-fetch';
import { semanticSuggestionsQuery } from '@api/graphql/semanticSuggestions';
import { semanticSearchApiUrl } from '@settings';
import type { SemanticSearchRequest, SemanticSearchResponse } from './types';

export const semanticSearch = async (requestBody: SemanticSearchRequest) => {
	const headers = new Headers();

	headers.append('Content-Type', 'application/json');

	const response = await fetch(semanticSearchApiUrl, {
		method: 'POST',
		headers,
		body: JSON.stringify(requestBody),
	});

	const data = await response.json();

	return data as SemanticSearchResponse;
};

interface SemanticSuggestionQueryVariables {
	searchText: string;
	categoryCode: string;
	filters: string[];
	location: { city: string; state: string };
}

export interface SemanticSuggestionResponse {
	search: {
		profiles: {
			id: string;
			name: string;
			location: {
				address: {
					address1: string;
					state: string;
					city: string;
				};
			};
			siteUrls: {
				relativeUri: string;
			}[];
			storefrontCard: {
				url: string;
			};
			categoryInfo: {
				seo: {
					plural: {
						term: string;
					};
				};
			};
		}[];
	};
	semanticSuggestions: {
		facet: string;
		facetId: string;
		searchTerm: string;
		semanticTerm: string;
		semanticType: string;
		similarityScore: number;
	}[];
}

export function semanticSuggestions(
	variables: SemanticSuggestionQueryVariables,
) {
	return graphqlFetch<
		SemanticSuggestionResponse,
		SemanticSuggestionQueryVariables
	>({
		query: semanticSuggestionsQuery,
		variables,
	});
}
