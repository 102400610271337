import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { DisplayButton } from '@xo-union/tk-ui-links';
import { Body2, H4 } from '@xo-union/tk-ui-typography';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { type FC, useEffect, useRef, useState } from 'react';
import AnalyticsConstants from '../../../../../../constants/analytics';
import Title from '../../ResultsHeader/Title';
import { locationToString } from '../../ResultsHeader/helpers';
import {
	fieldValueAtom,
	isSemanticSearchCalledAtom,
	resetSemanticSearchResponseAtom,
	searchFieldValueAtom,
	storefrontsCountAtom,
} from '../atoms';
import Styles from './SemanticTitle.styles.scss';

export interface SemanticTitleProps {
	lessThanSmall: boolean;
}

export const SemanticTitle: FC<SemanticTitleProps> = (props) => {
	const { lessThanSmall } = props;
	const columnRef = useRef<HTMLDivElement>(null);
	const textRef = useRef<HTMLHeadingElement>(null);
	const [maxColumnWidth, setMaxColumnWidth] = useState<number>(1000);
	const [isClearButtonInline, setIsClearButtonInline] = useState<boolean>(true);
	const [render, setRender] = useState<boolean>(false);
	const location = useAppSelector((state) => state.location);
	const categoryName = useAppSelector((state) => state.category.name);
	const storefrontsCount = useAtomValue(storefrontsCountAtom);
	const searchFieldValue = useAtomValue(searchFieldValueAtom);
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const resetSemanticSearchResponse = useSetAtom(
		resetSemanticSearchResponseAtom,
	);
	const setFieldValue = useSetAtom(fieldValueAtom);
	const { track } = useAnalyticsContext();

	const setupMaxColumnWidth = () => {
		if (columnRef.current) {
			setMaxColumnWidth(columnRef.current.offsetWidth);
		}
	};

	const updateRemainingWidth = () => {
		if (columnRef.current && textRef.current) {
			const textWidth = textRef.current.offsetWidth;
			const calculatedRemainingWidth = maxColumnWidth - textWidth;
			setIsClearButtonInline(calculatedRemainingWidth > 102); // 102 is the width of the Clear search button + padding
		}
	};

	useEffect(() => {
		if (!render) {
			setRender(true);
			setupMaxColumnWidth();
		}
	}, [render, setupMaxColumnWidth]);

	useEffect(() => {
		if (isSemanticSearchCalled) {
			setupMaxColumnWidth();
			updateRemainingWidth();
		}
		window.addEventListener('resize', updateRemainingWidth);

		return () => {
			window.removeEventListener('resize', updateRemainingWidth);
		};
	}, [isSemanticSearchCalled, setupMaxColumnWidth, updateRemainingWidth]);

	const pageLocation = locationToString(location);
	const headerText = `${storefrontsCount} results for "${searchFieldValue}"`;
	const subText = `in ${categoryName} in ${pageLocation}`;

	const clearSemanticSearch = () => {
		setFieldValue('');
		resetSemanticSearchResponse();
		track({
			event: 'Vendor Search Interaction',
			properties: {
				action: 'clear_search',
				sourcePage: AnalyticsConstants.SEMANTIC_RESULTS,
			},
		});
	};

	return (
		<div
			className={Styles.semanticHeader}
			ref={columnRef}
			style={{ maxWidth: `${maxColumnWidth}px` }}
		>
			{(!isSemanticSearchCalled || storefrontsCount === 0) && <Title />}
			{isSemanticSearchCalled && storefrontsCount > 0 && (
				<>
					<div className={Styles.headerTextLine}>
						<H4 className={Styles.headerText} as="h1" ref={textRef}>
							{headerText}
						</H4>
						{!lessThanSmall && isClearButtonInline && (
							<DisplayButton
								className={Styles.clearSearchButton}
								onClick={clearSemanticSearch}
								type="button"
							>
								Clear search
							</DisplayButton>
						)}
					</div>
					<div
						className={
							isClearButtonInline ? Styles.subText : Styles.subTextMultiline
						}
					>
						<Body2>{subText}</Body2>
						{(lessThanSmall || !isClearButtonInline) && (
							<DisplayButton
								className={Styles.clearSearchButton}
								onClick={clearSemanticSearch}
								type="button"
							>
								Clear search
							</DisplayButton>
						)}
					</div>
				</>
			)}
		</div>
	);
};
