import { semanticSuggestions } from '@api/semanticSearch';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { semanticSearchV2Selector } from '@redux/experiments/selectors/semantic-search-v2';
import { useAppSelector } from '@redux/hooks';
import { useAtom } from 'jotai';
import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import {
	isSemanticSuggestionsOpenAtom,
	semanticSearchTypeaheadAtom,
} from '../atoms';

export const useSemanticSuggestions = () => {
	const semanticSearchV2Assignment = useAppSelector((state) =>
		semanticSearchV2Selector(state),
	);
	const isInSemanticSearchV2 =
		semanticSearchV2Assignment === 'semantic-search-v2';
	const [isSemanticSuggestionsOpen, setIsSemanticSuggestionsOpen] = useAtom(
		isSemanticSuggestionsOpenAtom,
	);
	const categoryCode = useAppSelector((state) => state.category.code);
	const categoryId = useAppSelector((state) => state.category.id);
	const stateCode = useAppSelector((state) => state.location.stateCode);
	const city = useAppSelector((state) => state.location.city);
	const [semanticSearchTypeahead, setSemanticSearchTypeahead] = useAtom(
		semanticSearchTypeaheadAtom,
	);
	const { track } = useAnalyticsContext();
	const resetSuggestions = useCallback(() => {
		setSemanticSearchTypeahead({
			storefronts: [],
			semanticSearchResults: [],
		});
	}, [setSemanticSearchTypeahead]);

	const getSuggestions = useMemo(() => {
		if (!isInSemanticSearchV2) {
			return () => {};
		}
		return debounce(async (searchText) => {
			if (searchText.length < 3) {
				setSemanticSearchTypeahead({
					storefronts: [],
					semanticSearchResults: [],
				});
				return;
			}

			const res = await semanticSuggestions({
				searchText,
				categoryCode,
				filters: [categoryId],
				location: { city: city || '', state: stateCode || '' },
			});

			setIsSemanticSuggestionsOpen(searchText.length > 0);
			const storefronts = (res?.data?.search?.profiles || []).map((s) => {
				return {
					id: s.id,
					name: s.name,
					photoUrl: s.storefrontCard?.url,
					url: s.siteUrls[0]?.relativeUri,
					address: s.location.address.address1,
					city: s.location.address.city,
					state: s.location.address.state,
					category: s.categoryInfo?.seo?.plural?.term || '',
				};
			});
			const semanticSearchResults = (res?.data?.semanticSuggestions || []).map(
				(s) => {
					return {
						facetId: s.facetId,
						facet: s.facet,
						term: s.semanticTerm,
						type: s.semanticType,
						similarity_score: s.similarityScore,
					};
				},
			);
			setSemanticSearchTypeahead({
				storefronts,
				semanticSearchResults,
			});
		}, 300);
	}, [
		city,
		stateCode,
		categoryCode,
		categoryId,
		setSemanticSearchTypeahead,
		setIsSemanticSuggestionsOpen,
		isInSemanticSearchV2,
	]);

	const searchSuggestions = useMemo(() => {
		return semanticSearchTypeahead.semanticSearchResults.map((s) => {
			return {
				facetId: s?.facetId,
				key: (s.facet ?? s.term) as string,
				label: (s.facet ?? s.term) as string,
				type: s.facet ? ('facet' as const) : ('term' as const),
				searchTerm: s.term,
			};
		});
	}, [semanticSearchTypeahead.semanticSearchResults]);

	const storefrontSuggestions = useMemo(() => {
		return semanticSearchTypeahead.storefronts.map((s) => {
			return {
				type: 'storefront' as const,
				key: s.name,
				info: {
					name: s.name,
					url: s.url,
					photoUrl: s.photoUrl,
					category: s.category,
					location: `${s.address} ${s.city}, ${s.state}`,
				},
			};
		});
	}, [semanticSearchTypeahead.storefronts]);

	const openSuggestions = () => {
		if (!isInSemanticSearchV2) {
			return;
		}
		setIsSemanticSuggestionsOpen(true);
	};

	const closeSuggestions = () => {
		if (!isInSemanticSearchV2) {
			return;
		}
		setIsSemanticSuggestionsOpen(false);
		resetSuggestions();
	};

	return {
		isSemanticSuggestionsOpen,
		getSuggestions,
		closeSuggestions,
		openSuggestions,
		searchSuggestions,
		storefrontSuggestions,
	};
};
