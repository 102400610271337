import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { semanticSearchV2Selector } from '@redux/experiments/selectors/semantic-search-v2';
import { useAppSelector } from '@redux/hooks';
import { useSetAtom } from 'jotai';
import React, { useCallback } from 'react';
import {
	fieldValueAtom,
	updateIsFetchingAtom,
	updateIsSemanticSearchCalled,
} from '../atoms';
import { useSemanticSearch } from '../hooks/use-semantic-search';
import { useSemanticSuggestions } from '../hooks/use-semantic-suggestions';
import {
	SemanticSuggestionList,
	type SemanticTrackProperties,
} from './SemanticSuggestionList';
import Styles from './SemanticSuggestions.styles.scss';

export interface DividerProps {
	searchSuggestionsLength: number;
	storefrontSuggestionsLength: number;
}

export const Divider = (props: DividerProps) => {
	const { searchSuggestionsLength, storefrontSuggestionsLength } = props;

	if (searchSuggestionsLength === 0 || storefrontSuggestionsLength === 0) {
		return null;
	}

	return <hr className={Styles.divider} />;
};
export const SemanticSuggestions = () => {
	const semanticSearchV2Assignment = useAppSelector((state) =>
		semanticSearchV2Selector(state),
	);
	const setFieldValue = useSetAtom(fieldValueAtom);
	const setIsFetching = useSetAtom(updateIsFetchingAtom);
	const setIsSemanticSearchCalled = useSetAtom(updateIsSemanticSearchCalled);
	const isInSemanticSearchV2 =
		semanticSearchV2Assignment === 'semantic-search-v2';
	const { track } = useAnalyticsContext();
	const {
		searchSuggestions,
		storefrontSuggestions,
		isSemanticSuggestionsOpen,
		closeSuggestions,
	} = useSemanticSuggestions();
	const { handleSemanticSearch } = useSemanticSearch();

	const handleClick = useCallback(
		async (semanticTrackProperties: SemanticTrackProperties) => {
			const fieldValue = semanticTrackProperties.suggestion;
			const { position, searchTerm, searchText, sourcePage, suggestion } =
				semanticTrackProperties;
			track({
				event: 'Vendor Search Interaction',
				properties: {
					product: 'marketplace',
					action: 'search_completed',
					selection: 'semantic_suggestion',
					position,
					searchText,
					searchTerm,
					suggestion,
					sourcePage,
					sourceContent: 'free_search_box',
				},
			});
			closeSuggestions();
			setFieldValue(fieldValue);
			handleSemanticSearch(fieldValue);
			setIsSemanticSearchCalled(true);
		},
		[
			setFieldValue,
			closeSuggestions,
			setIsSemanticSearchCalled,
			handleSemanticSearch,
			track,
		],
	);

	if (
		!isInSemanticSearchV2 ||
		!isSemanticSuggestionsOpen ||
		(!searchSuggestions.length && !storefrontSuggestions.length)
	) {
		return null;
	}

	return (
		<div className={Styles.wrapper}>
			<SemanticSuggestionList
				suggestions={searchSuggestions}
				handleClick={handleClick}
			/>
			<Divider
				searchSuggestionsLength={searchSuggestions.length}
				storefrontSuggestionsLength={storefrontSuggestions.length}
			/>
			<SemanticSuggestionList
				suggestions={storefrontSuggestions}
				handleClick={handleClick}
			/>
		</div>
	);
};
