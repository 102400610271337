import { selectAssignment } from '@redux/experiments/selectors/index';
import type { PVRec2Assignment } from '@typings/experiments';
import type { State } from 'types/redux';

const pvrec2AssignmentSelector = (state: State) => {
	const categories = new Set([
		'BDS',
		'BEA',
		'BWP',
		'CAT',
		'CER',
		'DJS',
		'FLO',
		'PLN',
		'REC',
		'SPV',
		'VID',
		'WCK',
		'WPH',
	]);
	if (!categories.has(state.category.code) || state.viewport.isMobile) {
		return null;
	}

	return selectAssignment(state, ['pvrec2']) as PVRec2Assignment;
};

export { pvrec2AssignmentSelector };
