import { PhotoPictureWrapper } from '@components/photo-picture-wrapper';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { Img } from '@xo-union/tk-component-picture';
import { Body1, Caption } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { type FC, useMemo } from 'react';
import searchFacetSeoText from '../../../../../../helpers/seo/searchFacetSeoText';
import { NO_IMAGE_PATH } from '../../../../Storefront/components/Media/constants';
import { fieldValueAtom, isSemanticSearchCalledAtom } from '../atoms';
import Styles from './SemanticSuggestionList.styles.scss';

interface FacetSuggestionProps {
	semanticTrackProperties: SemanticTrackProperties;
}

interface StorefrontSuggestionProps {
	semanticTrackProperties: SemanticTrackProperties;
	suggestion: StorefrontSuggestion;
}

export interface FacetOrTermSuggestion {
	type: 'facet' | 'term';
	key: string;
	label: string;
	facetId?: string;
	searchTerm?: string;
}

export interface StorefrontSuggestion {
	type: 'storefront';
	key: string;
	info: {
		name: string;
		url: string;
		photoUrl: string;
		category: string;
		location: string;
	};
}

export interface SemanticTrackProperties {
	facetId?: string;
	position: number;
	searchTerm?: string;
	searchText: string;
	sourcePage: string;
	suggestion: string;
}

export type SemanticSuggestion = FacetOrTermSuggestion | StorefrontSuggestion;
export interface SemanticSuggestionListProps {
	suggestions: SemanticSuggestion[];
	handleClick: (semanticTrackProperties: SemanticTrackProperties) => void;
}

const isStorefrontSuggestion = (
	suggestion: SemanticSuggestion,
): suggestion is StorefrontSuggestion => {
	return suggestion.type === 'storefront';
};
const mixedWeightFonts = (text: string, highlight: string) => {
	if (!highlight || highlight.length < 3) {
		return <b>{text}</b>;
	}
	const parts = text.split(new RegExp(`(${highlight})`, 'i'));

	return (
		<>
			{parts.map((part) => {
				if (part === '') {
					return '';
				}
				const key = `${part || ''}-${Math.random()}`;
				if (part.toLowerCase() === highlight.toLowerCase()) {
					return <span key={key}>{highlight}</span>;
				}
				return <b key={key}>{part}</b>;
			})}
		</>
	);
};

const FacetSuggestion: FC<FacetSuggestionProps> = (props) => {
	const { semanticTrackProperties } = props;
	const { track } = useAnalyticsContext();
	const { facetId, position, searchTerm, searchText, sourcePage, suggestion } =
		semanticTrackProperties;
	const canonical = useAppSelector((state) => state.seo.canonicalUrl);
	const singularFacet = searchFacetSeoText.find(
		(facet) =>
			facet?.filter_value &&
			suggestion &&
			facet.filter_value.toLowerCase() === suggestion.toLowerCase(),
	)?.singular;
	const facetName = useMemo(
		() => mixedWeightFonts(suggestion, searchText),
		[suggestion, searchText],
	);
	if (!singularFacet) {
		return null;
	}
	const url =
		process.env.NODE_ENV !== 'production'
			? `${canonical.replace('https://www.theknot.com', 'https://qa-beta.theknot.com')}/${singularFacet}`
			: `${canonical}/${singularFacet}`;

	const trackClick = () => {
		track({
			event: 'Vendor Search Interaction',
			properties: {
				product: 'marketplace',
				action: 'search_completed',
				selection: 'facet_suggestion',
				facetId,
				position,
				searchText,
				searchTerm,
				suggestion,
				sourcePage,
				sourceContent: 'free_search_box',
			},
		});
	};
	return (
		<li className={Styles.item}>
			<a href={url} onClick={trackClick}>
				{facetName}
			</a>
		</li>
	);
};

const StorefrontSuggestion: FC<StorefrontSuggestionProps> = (props) => {
	const { semanticTrackProperties, suggestion } = props;
	const { position, searchTerm, searchText, sourcePage } =
		semanticTrackProperties;
	const { track } = useAnalyticsContext();
	const { info } = suggestion;
	const { category, location, name, url, photoUrl } = info;
	const photoId = useMemo(() => photoUrl?.split('/').pop(), [photoUrl]);

	const trackClick = () => {
		track({
			event: 'Vendor Search Interaction',
			properties: {
				product: 'marketplace',
				action: 'search_completed',
				selection: 'vendor_suggestion',
				position,
				searchText,
				searchTerm,
				suggestion: name,
				sourcePage,
				sourceContent: 'free_search_box',
			},
		});
	};
	const storefrontName = mixedWeightFonts(name, searchText);

	return (
		<li className={Styles.item}>
			<a
				href={url}
				onClick={trackClick}
				rel="noreferrer noopener"
				target="_blank"
			>
				<div className={Styles.storefrontWrapper}>
					<div className={Styles.storefrontImageContainer}>
						{photoId ? (
							<PhotoPictureWrapper
								id={photoId}
								imgProps={{
									className: Styles.storefrontImage,
								}}
								lazy
								smartCrop
								width={60}
								height={60}
							/>
						) : (
							<div className={Styles.storefrontNoImageWrapper}>
								<Img
									className={Styles.storefrontNoImage}
									src={NO_IMAGE_PATH}
									alt="None"
								/>
							</div>
						)}
					</div>
					<div className={Styles.storefrontContentContainer}>
						<Caption className={Styles.category} size="sm">
							{category}
						</Caption>
						<Body1>{storefrontName}</Body1>

						<Caption size="lg" className={Styles.location}>
							{location}
						</Caption>
					</div>
				</div>
			</a>
		</li>
	);
};

interface SuggestionProps {
	fieldValue: string;
	handleClick: (semanticTrackProperties: SemanticTrackProperties) => void;
	semanticTrackProperties: SemanticTrackProperties;
	suggestion: SemanticSuggestion;
}

const Suggestion: FC<SuggestionProps> = (props) => {
	const { fieldValue, handleClick, semanticTrackProperties, suggestion } =
		props;
	return (
		<li className={Styles.item}>
			<button
				type="button"
				onClick={() => handleClick(semanticTrackProperties)}
			>
				{mixedWeightFonts(suggestion.label, fieldValue)}
			</button>
		</li>
	);
};

export const SemanticSuggestionList: FC<SemanticSuggestionListProps> = (
	props,
) => {
	const { suggestions, handleClick } = props;
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const fieldValue = useAtomValue(fieldValueAtom);
	const sourcePage = isSemanticSearchCalled
		? 'semantic_results'
		: 'category results';
	return (
		<ul className={Styles.list}>
			{suggestions.map((s, idx) => {
				const type = s.type || '';
				const semanticTrackBaseProperties = {
					position: idx + 1,
					searchTerm: s.searchTerm,
					searchText: fieldValue,
					sourcePage,
					suggestion: s.label || s?.info?.name || '',
				};
				const semanticTrackProperties = s.facetId
					? { ...semanticTrackBaseProperties, facetId: s.facetId }
					: semanticTrackBaseProperties;
				if (isStorefrontSuggestion(s)) {
					return (
						<StorefrontSuggestion
							key={s.key}
							semanticTrackProperties={semanticTrackProperties}
							suggestion={s}
						/>
					);
				}
				if (type === 'facet') {
					return (
						<FacetSuggestion
							key={s.key}
							semanticTrackProperties={semanticTrackProperties}
						/>
					);
				}
				return (
					<Suggestion
						fieldValue={fieldValue}
						key={s.key}
						handleClick={handleClick}
						semanticTrackProperties={semanticTrackProperties}
						suggestion={s}
					/>
				);
			})}
		</ul>
	);
};
