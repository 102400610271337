import { semanticSearchV2Selector } from '@redux/experiments/selectors/semantic-search-v2';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { Field, Form, type PseudoEvent } from '@xo-union/tk-component-fields';
import { IconButton } from '@xo-union/tk-component-icons';
import { FullscreenModal } from '@xo-union/tk-component-modals';
import { Body1, Subhead } from '@xo-union/tk-ui-typography';
import { useAtom, useSetAtom } from 'jotai';
import React, { useCallback, useRef } from 'react';
import { fieldValueAtom, resetSemanticSearchResponseAtom } from '../atoms';
import { useSemanticSearch } from '../hooks/use-semantic-search';
import { useSemanticSuggestions } from '../hooks/use-semantic-suggestions';
import Styles from './MobileSemanticSuggestions.styles.scss';
import FormStyles from './SemanticSearch.styles.scss';
import { SemanticSuggestions } from './SemanticSuggestions';

const fieldClasses = compose({
	container: FormStyles.fieldContainer,
	input: FormStyles.fieldInput,
});

export const MobileSemanticSuggestions = () => {
	const [fieldValue, setFieldValue] = useAtom(fieldValueAtom);
	const closeButtonRef = useRef<HTMLButtonElement>(null);
	const semanticSearchV2Assignment = useAppSelector((state) =>
		semanticSearchV2Selector(state),
	);
	const location = useAppSelector(
		(state) => `${state.location.city}, ${state.location.stateCode}`,
	);
	const selectedCategoryName = useAppSelector((state) => state.category.name);

	const resetSemanticSearchResponse = useSetAtom(
		resetSemanticSearchResponseAtom,
	);
	const { isSemanticSuggestionsOpen, getSuggestions, closeSuggestions } =
		useSemanticSuggestions();

	const { handleSemanticSearch } = useSemanticSearch();
	const categoryInLocation = `${selectedCategoryName} in ${location}`;
	const isInSemanticSearchV2 =
		semanticSearchV2Assignment === 'semantic-search-v2';

	const handleMobileClose = useCallback(() => {
		if (isSemanticSuggestionsOpen) {
			setFieldValue('');
			resetSemanticSearchResponse();
			closeSuggestions();
		}
	}, [
		isSemanticSuggestionsOpen,
		resetSemanticSearchResponse,
		setFieldValue,
		closeSuggestions,
	]);

	const handleSearchTextChange = async (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const value = e.target.value;
		setFieldValue(value);
		getSuggestions(value);
	};

	const handleSubmit = (pseudoEvent: PseudoEvent) => {
		if (!pseudoEvent.valid) {
			return;
		}
		handleSemanticSearch(fieldValue);
		closeSuggestions();
	};

	const handleFieldClick = () => {
		if (
			document.activeElement === closeButtonRef?.current &&
			fieldValue.length > 0
		) {
			resetSemanticSearchResponse();
			closeSuggestions();
			setFieldValue('');
		}
	};

	if (!isInSemanticSearchV2 || !isSemanticSuggestionsOpen) {
		return null;
	}

	return (
		<FullscreenModal
			onClose={handleMobileClose}
			showLogo={false}
			classes={compose({ header: Styles.header, container: Styles.container })}
		>
			<div className={Styles.wrapper}>
				<Subhead bold className={Styles.subhead}>
					Search
				</Subhead>
				<Body1 className={Styles.categoryLocation}>{categoryInLocation}</Body1>
				<Form className={Styles.form} onSubmit={handleSubmit}>
					<Field
						classes={fieldClasses}
						label={'Search vendors, styles, details'}
						name="search..."
						onChange={handleSearchTextChange}
						validations={{ required: true }}
						value={fieldValue}
						subTextOnInvalid=""
						id="semantic-search-field"
					/>
					<IconButton<HTMLButtonElement>
						aria-label="clear search field"
						className={
							fieldValue.length > 0
								? FormStyles.clearButton
								: FormStyles.clearButtonHidden
						}
						name="close_circle"
						onClick={handleFieldClick}
						ref={closeButtonRef}
						size="md"
					/>
					<Button
						className={FormStyles.semanticSearchButton}
						color="primary"
						iconName="search"
						size="lg"
						type="submit"
						aria-label="Submit search"
					/>
				</Form>
				<SemanticSuggestions />
			</div>
		</FullscreenModal>
	);
};
